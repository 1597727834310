// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";


// Bootstrap Icons
@import '../node_modules/bootstrap-icons/font/bootstrap-icons';

// Ng-Select
@import "@ng-select/ng-select/themes/default.theme.css";

// ngx-contextmenu
@import '@angular/cdk/overlay-prebuilt.css';
